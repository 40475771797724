import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { GroupLimitation, TenantLimitation } from '@/base/domains';
import { config } from '@/config';
import { assertIsDefined } from '@/utils/Asserts';

import { GroupLimitationSubmitPayload } from './GroupLimitationComposable';

export type GroupLimitationsSubmitPayload = {
  value: GroupLimitation;
  done: () => void;
};

export type PropsGroupLimitations = {
  value: GroupLimitation;
  tenantLimitations: TenantLimitation;
};

export function useGroupLimitations(
  props: PropsGroupLimitations,
  emit: (name: string, arg: GroupLimitationsSubmitPayload) => void
) {
  const input = ref<GroupLimitation>();

  function init() {
    input.value = { ...props.value };
  }
  onMounted(init);
  watch(() => props.value, init);

  async function submit(payload: GroupLimitationSubmitPayload) {
    assertIsDefined(input.value);
    emit('submit', {
      value: { ...input.value, [payload.name]: payload.value },
      done: payload.done,
    });
  }

  const msgs = useMessages({ prefix: 'admin.molecules.groupLimitations' });
  const features = computed(() => {
    if (!input.value) return [];
    const { tenantLimitations: tl } = props;
    return Object.entries(input.value)
      .filter(([name]) => name in tl && tl[name] === 'enabled')
      .map(([name, value]) => ({ name, value, label: msgs.of(name).value }))
      .sort((a, b) => (a.name < b.name ? -1 : 1));
  });

  return {
    features,
    featuresHelpUrl: config().app.groupLimitationHelpUrl,
    labelFeatures: msgs.of('features'),
    labelHelp: msgs.of('featuresHelp'),
    noData: msgs.of('noData'),
    submit,
  };
}
