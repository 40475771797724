import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { assertIsDefined } from '@/utils/Asserts';

export type GroupLimitationFormValue = 'inactive' | 'active';

export type PropsGroupLimitationForm = {
  value?: GroupLimitationFormValue;
};

export function useGroupLimitationForm(
  props: PropsGroupLimitationForm,
  emit: (name: string, arg: GroupLimitationFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<GroupLimitationFormValue>();

  function init() {
    input.value = props.value;
  }
  onMounted(init);
  watch(() => props.value, init);

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    assertIsDefined(input.value, 'input');
    emit('submit', input.value);
  }

  const msgs = useMessages({ prefix: 'admin.molecules.groupLimitationForm' });
  return {
    observer,
    input,
    items: msgs.listOf('items'),
    submit,
  };
}
