


















import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseText from '@/base/app/components/atoms/BaseText.vue';

import { PropsGroupName, useGroupNameForm } from './GroupNameFormComposable';

type Props = PropsGroupName;

export default defineComponent({
  name: 'AdminGroupNameForm',
  components: { BaseText },
  model: { prop: 'value', event: 'submit' },
  props: {
    value: { type: String, default: undefined },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useGroupNameForm(props, emit);
  },
});
