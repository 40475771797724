import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { assertIsDefined } from '@/utils/Asserts';

export type GroupDescriptionSubmitPayload = {
  value: string;
  done: () => void;
};

export type PropsGroupDescription = {
  value?: string;
};

export function useGroupDescription(
  props: PropsGroupDescription,
  emit: (name: string, arg: GroupDescriptionSubmitPayload) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<string>();

  function init() {
    input.value = props.value;
  }
  onMounted(init);
  watch(() => props.value, init);

  const editing = ref(false);
  watch(editing, init);

  const updating = ref(false);
  function done() {
    updating.value = false;
    editing.value = false;
  }

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    updating.value = true;
    assertIsDefined(input.value, 'input');
    emit('submit', { value: input.value, done });
  }

  const msgs = useMessages({ prefix: 'admin.molecules.groupDescription' });
  return {
    observer,
    input,
    editing,
    updating,
    label: msgs.of('description'),
    labelEdit: msgs.of('edit'),
    labelUpdate: msgs.of('update'),
    labelCancel: msgs.of('cancel'),
    noData: msgs.of('noData'),
    submit,
  };
}
