














































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import { GroupLimitation, GroupRole } from '@/base/domains';

import { PropsGroupLimitation, useGroupLimitation } from './GroupLimitationComposable';
import GroupLimitationForm from './GroupLimitationForm.vue';

type Props = PropsGroupLimitation;

export default defineComponent({
  name: 'AdminGroupLimitation',
  components: { BaseButton, BaseButtonIcon, GroupLimitationForm },
  props: {
    name: { type: String as PropType<keyof GroupLimitation>, required: true },
    value: { type: Array as PropType<GroupRole[]>, default: () => [] },
    label: { type: String, required: true },
    readonly: { type: Boolean, default: false },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useGroupLimitation(props, emit);
  },
});
