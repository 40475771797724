

































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import { GroupLimitation, TenantLimitation } from '@/base/domains';

import GroupLimitationVue from './GroupLimitation.vue';
import { PropsGroupLimitations, useGroupLimitations } from './GroupLimitationsComposable';

type Props = PropsGroupLimitations;

export default defineComponent({
  name: 'AdminGroupLimitations',
  components: { BaseButtonIcon, GroupLimitation: GroupLimitationVue },
  props: {
    value: { type: Object as PropType<GroupLimitation>, required: true },
    tenantLimitations: { type: Object as PropType<TenantLimitation>, required: true },
    readonly: { type: Boolean, default: false },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useGroupLimitations(props, emit);
  },
});
