
















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseTable from './BaseTable.vue';
import { PropsUserTable, UserTableItem, useUserTable } from './UserTableComposable';

type Props = PropsUserTable;

export default defineComponent({
  name: 'BaseUserTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    users: { type: Array as PropType<UserTableItem[]>, default: () => [] },
    headerKeys: { type: Array as PropType<string[]>, default: () => ['name', 'role'] },
  },
  emits: ['action'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserTable(props, emit);
  },
});
