import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { BaseDialogOk } from '@/base/app/components/molecules/BaseDialogOkComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { useGlobalStore } from '@/base/app/store';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject } from '@/utils/VueUtils';

import { GroupStoreKey } from '../../stores';
import { GroupDescriptionSubmitPayload } from '../molecules/GroupDescriptionComposable';
import { GroupLimitationsSubmitPayload } from '../molecules/GroupLimitationsComposable';
import { GroupNameSubmitPayload } from '../molecules/GroupNameComposable';
import { GroupUsersAddPayload, GroupUsersRemovePayload } from '../molecules/GroupUsersComposable';

function useErrorDialog() {
  const errorDialog = ref<BaseDialogOk>();
  function error(errors: ErrorMessage[]) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.error(errors);
  }
  return { errorDialog, error };
}

export type PropsGroup = {
  id: string;
};

export function useGroup(props: PropsGroup) {
  const {
    group,
    tenantUsers,
    fetch,
    changeGroupName,
    changeGroupDescription,
    changeLimitations,
    changeGroupUsers,
  } = requiredInject(GroupStoreKey);
  const { tenant } = useGlobalStore();
  const { errorDialog, error } = useErrorDialog();

  const displayNamePanel = ref(true);
  const colsNamePanel = computed(() => (displayNamePanel.value ? '3' : 'auto'));
  const colsUsersPanel = computed(() => (displayNamePanel.value ? '9' : ''));

  function init() {
    fetch(props.id);
  }
  onMounted(init);
  watch(() => props.id, init);

  async function submitName(payload: GroupNameSubmitPayload) {
    const ret = await changeGroupName(payload.value);
    if (ret !== true) {
      error(ret);
      payload.done();
      return;
    }
    waitTransition(payload.done);
  }

  async function submitDescription(payload: GroupDescriptionSubmitPayload) {
    const ret = await changeGroupDescription(payload.value);
    if (ret !== true) {
      error(ret);
      payload.done();
      return;
    }
    waitTransition(payload.done);
  }

  async function submitLimitations(payload: GroupLimitationsSubmitPayload) {
    const ret = await changeLimitations(payload.value);
    if (ret !== true) {
      error(ret);
      payload.done();
      return;
    }
    waitTransition(payload.done);
  }

  async function addUsers(payload: GroupUsersAddPayload) {
    const ret = await changeGroupUsers(payload.ids, payload.role);
    if (ret !== true) {
      error(ret);
      payload.done();
      return;
    }
    waitTransition(payload.done);
  }

  async function removeUsers(payload: GroupUsersRemovePayload) {
    const ret = await changeGroupUsers(payload.ids);
    if (ret !== true) {
      error(ret);
      payload.done();
      return;
    }
    waitTransition(payload.done);
  }

  const msgs = useMessages({ prefix: 'admin.organisms.group' });
  const status = computed(() => {
    if (!group.value) return undefined;
    const key = group.value.enabled ? 'enabled' : 'disabled';
    return msgs.of(key).value;
  });

  return {
    errorDialog,
    group,
    tenant,
    tenantUsers,
    displayNamePanel,
    colsNamePanel,
    colsUsersPanel,
    status,
    labelStatus: msgs.of('status'),
    submitName,
    submitDescription,
    submitLimitations,
    addUsers,
    removeUsers,
  };
}
