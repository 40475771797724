







import { defineComponent } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import MyApp from '@/base/app/components/MyApp.vue';

import Groups from '../components/organisms/Groups.vue';

export default defineComponent({
  name: 'AdminGroupsPage',
  components: { MyApp, Groups },
  setup() {
    const msgs = useMessages({ prefix: 'admin.pages.groupsPage' });
    return { labelGroupManagement: msgs.of('groupManagement') };
  },
});
