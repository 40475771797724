


































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';

import { PropsGroupName, useGroupName } from './GroupNameComposable';
import GroupNameForm from './GroupNameForm.vue';

type Props = PropsGroupName;

export default defineComponent({
  name: 'AdminGroupName',
  components: { BaseButton, BaseButtonIcon, GroupNameForm },
  props: {
    value: { type: String, required: true },
    readonly: { type: Boolean, default: false },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useGroupName(props, emit);
  },
});
