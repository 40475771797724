import { computed, ref } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';
import {
  BaseTable,
  BaseTableActionPayload,
} from '@/base/app/components/molecules/BaseTableComposable';
import { Group, GroupRole, GroupUser, User } from '@/base/domains';

const HEADERS: DataTableHeader[] = [
  { value: 'name', text: '', class: 'base-table-name' },
  { value: 'trainee', text: '', width: 150, align: 'center' },
  { value: 'trainer', text: '', width: 150, align: 'center' },
  { value: 'mentor', text: '', width: 150, align: 'center' },
];

function useTable() {
  const table = ref<BaseTable>();
  function clear() {
    if (table.value) table.value.clearSelection();
  }
  return { table, clear };
}

export type PropsGroupTable = {
  groups: Group[];
  tenantUsers: User[];
  headerKeys: string[];
};

export type GroupTableActionPayload = {
  event: string;
  selected: Group[];
};

export function useGroupTable(
  props: PropsGroupTable,
  emit: (name: string, args: GroupTableActionPayload) => void
) {
  const msgs = useMessages({ prefix: 'admin.molecules.groupTable' });
  const headers = computed(() =>
    props.headerKeys
      .map((value) => HEADERS.find((h) => h.value === value))
      .filter((h) => !!h)
      .map((h) => ({ ...h, text: h ? msgs.of(h.value).value : '' }))
  );

  const items = computed(() => {
    const roles: GroupRole[] = ['trainee', 'trainer', 'mentor'];
    const getUserLength = (gUsers: GroupUser[], role: GroupRole) =>
      gUsers
        .filter((u) => u.role === role && !u.removed)
        .filter((u) => props.tenantUsers.some((tu) => tu.id === u.id && tu.enabled)).length;
    return props.groups
      .map((g) => ({
        ...g,
        ...roles
          .map((key) => ({ [key]: getUserLength(g.users, key) }))
          .reduce((p, c) => Object.assign(p, c), {}),
      }))
      .sort((a, b) => (a.name < b.name ? -1 : 1));
  });

  function action(payload: BaseTableActionPayload) {
    const selected = payload.selected
      .map((id) => props.groups.find((g) => g.id === id))
      .filter((g) => g) as Group[];
    emit('action', { ...payload, selected });
  }

  return { headers, items, action, ...useTable() };
}

export type GroupTable = ReturnType<typeof useGroupTable>;
