import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { TextEditorClickAnchorPayload } from '@/base/app/components/molecules/TextEditorComposable';
import { assertIsDefined } from '@/utils/Asserts';

export type TenantOwnTermsOfServiceFormClickAnchorPayload = TextEditorClickAnchorPayload;

export type TenantOwnTermsOfServiceFormValue = string;

export type PropsTenantOwnTermsOfServiceForm = {
  value: TenantOwnTermsOfServiceFormValue;
};

export function useTenantOwnTermsOfServiceForm(
  props: PropsTenantOwnTermsOfServiceForm,
  emit: (
    name: string,
    arg: TenantOwnTermsOfServiceFormValue | TenantOwnTermsOfServiceFormClickAnchorPayload
  ) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<TenantOwnTermsOfServiceFormValue>();

  function init() {
    input.value = props.value;
  }
  onMounted(init);
  watch(() => props.value, init);

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    assertIsDefined(input.value, 'input');
    emit('submit', input.value);
  }

  function clickAnchor(payload: TextEditorClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  const msgs = useMessages({ prefix: 'admin.molecules.tenantOwnTermsOfServiceForm' });
  return { observer, input, label: msgs.of('body'), submit, clickAnchor };
}
