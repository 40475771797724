import { render, staticRenderFns } from "./GroupAddDialog.vue?vue&type=template&id=5243d656&"
import script from "./GroupAddDialog.vue?vue&type=script&lang=ts&"
export * from "./GroupAddDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VDialog,VSheet})
