












import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseTable from '@/base/app/components/molecules/BaseTable.vue';
import { Group, User } from '@/base/domains';

import { PropsGroupTable, useGroupTable } from './GroupTableComposable';

type Props = PropsGroupTable;

export default defineComponent({
  name: 'AdminGroupTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    groups: { type: Array as PropType<Group[]>, default: () => [] },
    tenantUsers: { type: Array as PropType<User[]>, default: () => [] },
    headerKeys: {
      type: Array as PropType<string[]>,
      default: () => ['name', 'trainee', 'trainer', 'mentor'],
    },
  },
  emits: ['action'],
  setup(props: Props, { emit }: SetupContext) {
    return useGroupTable(props, emit);
  },
});
