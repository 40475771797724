import { computed, onMounted, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { BaseMarkdownClickAnchorPayload } from '@/base/app/components/atoms/BaseMarkdownComposable';
import { DialogAnchorConfirm } from '@/base/app/components/organisms/DialogAnchorConfirmComposable';
import { LocalDateTime } from '@/base/types';
import { isFailed } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { useGetEditingTenantOwnTermsOfService } from '../../../usecases';

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(payload: BaseMarkdownClickAnchorPayload) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

export function useTenantOwnTermsOfService() {
  const msgs = useMessages({ prefix: 'admin.organisms.tenantOwnTermsOfService' });
  const route = useRoute();
  const router = useRouter();

  const termsOfService = ref<{ body: string; createdAt: LocalDateTime }>();
  const existsDraft = ref(false);
  const title = computed(() => {
    if (!termsOfService.value) return undefined;
    return msgs.of('createdAt', {
      date: termsOfService.value.createdAt.format('YYYY/MM/DD HH:mm'),
    }).value;
  });
  const body = computed(() => termsOfService.value?.body);
  const labelEdit = computed(() => {
    const key = existsDraft.value ? 'showDraft' : 'createDraft';
    return msgs.of(key).value;
  });

  const loading = ref(false);
  const updating = ref(false);

  const getTenantOwnTermsOfService = useGetEditingTenantOwnTermsOfService();
  async function fetch() {
    loading.value = true;
    const res = await getTenantOwnTermsOfService.execute({});
    loading.value = false;
    if (isFailed(res)) {
      router.replace({ name: 'notFound' });
      return;
    }
    termsOfService.value = res.tenantOwnTermsOfService;
    existsDraft.value = !!res.editingTenantOwnTermsOfService;
  }

  async function init() {
    await fetch();
    if (!existsDraft.value || 'force' in route.query) return;

    router.replace({ name: 'adminTenantOwnTermsOfServiceDraft' });
  }
  onMounted(init);

  async function edit() {
    router.push({ name: 'adminTenantOwnTermsOfServiceDraft' });
  }

  return {
    title,
    body,
    labelEdit,
    loading,
    updating,
    noData: msgs.of('noData'),
    edit,
    ...useAnchorDialog(),
  };
}
