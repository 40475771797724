




















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import TextEditor from '@/base/app/components/molecules/TextEditor.vue';

import {
  PropsTenantOwnTermsOfServiceForm,
  TenantOwnTermsOfServiceFormValue,
  useTenantOwnTermsOfServiceForm,
} from './TenantOwnTermsOfServiceFormComposable';

type Props = PropsTenantOwnTermsOfServiceForm;

export default defineComponent({
  name: 'AdminTenantOwnTermsOfServiceForm',
  components: { TextEditor },
  model: { prop: 'value', event: 'submit' },
  props: {
    value: { type: String as PropType<TenantOwnTermsOfServiceFormValue>, required: true },
    height: { type: String, default: '80vh' },
  },
  emits: ['submit', 'click-anchor'],
  setup(props: Props, { emit }: SetupContext) {
    return useTenantOwnTermsOfServiceForm(props, emit);
  },
});
