import { computed, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import {
  UserTable,
  UserTableActionPayload,
} from '@/base/app/components/molecules/UserTableComposable';
import { GroupRole, GroupUser, SignUpType, User } from '@/base/domains';

const MENUS_NOT_BELONG_TO = [
  { value: 'trainee', required: true },
  { value: 'trainer', required: true },
  { value: 'mentor', required: true },
];

const MENUS_BELONG_TO = [{ value: 'remove', required: true }];

function useTable() {
  const table = ref<UserTable>();
  function clear() {
    if (table.value) table.value.clear();
  }
  return { table, clear };
}

export type GroupUsersAddPayload = {
  ids: string[];
  role: GroupRole;
  done: () => void;
};

export type GroupUsersRemovePayload = {
  ids: string[];
  done: () => void;
};

export type PropsGroupUsers = {
  users: GroupUser[];
  tenantUsers: User[];
  tenantSignUpType: SignUpType;
};

export function useGroupUsers(
  props: PropsGroupUsers,
  emit: (name: string, args: GroupUsersAddPayload | GroupUsersRemovePayload) => void
) {
  const msgs = useMessages({ prefix: 'admin.molecules.groupUsers' });
  const { table, clear: clearTableSelection } = useTable();

  const notBelongTo = ref(false);
  watch(notBelongTo, clearTableSelection);

  const updating = ref(false);
  function done() {
    updating.value = false;
    clearTableSelection();
  }

  function action({ event, selected }: UserTableActionPayload) {
    switch (event) {
      case 'trainer':
      case 'mentor':
      case 'trainee': {
        if (selected.length === 0) return;
        updating.value = true;
        emit('add', { ids: selected.map((item) => item.id), role: event, done });
        break;
      }
      case 'remove': {
        if (selected.length === 0) return;
        updating.value = true;
        emit('remove', { ids: selected.map((item) => item.id), done });
        break;
      }
      default:
    }
  }

  const keys = computed(() => {
    const ret = ['name', 'preview', 'role'];
    if (!notBelongTo.value) ret.splice(2, 0, 'groupRole');
    if (props.tenantSignUpType === 'user_code') ret.push('code');
    if (props.tenantSignUpType === 'email') ret.push('email');
    return ret;
  });

  const items = computed(() => {
    const groupUser = (id: string) => props.users.find((u) => u.id === id && !u.removed);
    return props.tenantUsers
      .map((tu) => {
        const groupRole = groupUser(tu.id)?.role;
        return { ...tu, groupRole };
      })
      .filter((u) => notBelongTo.value === !u.groupRole);
  });

  const menus = computed(() => {
    return (notBelongTo.value ? MENUS_NOT_BELONG_TO : MENUS_BELONG_TO).map((item) => ({
      ...item,
      label: msgs.of(item.value).value,
    }));
  });

  return {
    table,
    notBelongTo,
    updating,
    keys,
    items,
    menus,
    labelNotBelongTo: msgs.of('notBelongToGroup'),
    action,
  };
}
