




















































import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';
import DialogAnchorConfirm from '@/base/app/components/organisms/DialogAnchorConfirm.vue';
import Loading from '@/base/app/components/organisms/Loading.vue';

import TenantOwnTermsOfServiceForm from '../molecules/TenantOwnTermsOfServiceForm.vue';
import { useTenantOwnTermsOfServiceDraft } from './TenantOwnTermsOfServiceDraftComposable';

export default defineComponent({
  name: 'AdminTenantOwnTermsOfServiceDraft',
  components: {
    BaseButton,
    BaseMarkdown,
    ErrorMessages,
    Loading,
    TenantOwnTermsOfServiceForm,
    BaseDialogConfirm,
    DialogAnchorConfirm,
  },
  setup() {
    return useTenantOwnTermsOfServiceDraft();
  },
});
