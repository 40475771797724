
















import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import DialogAnchorConfirm from '@/base/app/components/organisms/DialogAnchorConfirm.vue';
import Loading from '@/base/app/components/organisms/Loading.vue';

import { useTenantOwnTermsOfService } from './TenantOwnTermsOfServiceComposable';

export default defineComponent({
  name: 'AdminTenantOwnTermsOfService',
  components: { BaseMarkdown, BaseButton, Loading, DialogAnchorConfirm },
  setup() {
    return useTenantOwnTermsOfService();
  },
});
