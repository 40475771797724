
























import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import GroupNameForm from '../molecules/GroupNameForm.vue';
import { useGroupAddDialog } from './GroupAddDialogComposable';

export default defineComponent({
  name: 'AdminGroupAddDialog',
  components: { BaseButton, GroupNameForm, ErrorMessages },
  inheritAttrs: false,
  emits: ['done'],
  setup(_, { emit }: SetupContext) {
    return useGroupAddDialog(emit);
  },
});
