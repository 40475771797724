import { onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { assertIsDefined } from '@/utils/Asserts';

export type GroupNameSubmitPayload = {
  value: string;
  done: () => void;
};

export type PropsGroupName = {
  value: string;
};

export function useGroupName(
  props: PropsGroupName,
  emit: (name: string, arg: GroupNameSubmitPayload) => void
) {
  const input = ref<string>();

  function init() {
    input.value = props.value;
  }
  onMounted(init);
  watch(() => props.value, init);

  const editing = ref(false);
  watch(editing, init);

  const updating = ref(false);
  function done() {
    updating.value = false;
    editing.value = false;
  }

  async function submit() {
    assertIsDefined(input.value, 'input');
    updating.value = true;
    emit('submit', { value: input.value, done });
  }

  const msgs = useMessages({ prefix: 'admin.molecules.groupName' });
  return {
    input,
    editing,
    updating,
    label: msgs.of('name'),
    labelEdit: msgs.of('edit'),
    labelUpdate: msgs.of('update'),
    labelCancel: msgs.of('cancel'),
    submit,
  };
}
