import { ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { isFailed } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';

import { useCreateGroup } from '../../../usecases';
import { GroupNameForm } from '../molecules/GroupNameFormComposable';

export function useGroupAddDialog(emit: (name: string) => void) {
  const dialog = ref(false);
  const form = ref<GroupNameForm>();
  const name = ref<string>();

  const updating = ref(false);
  const errors = ref<ErrorMessage[]>();

  function close() {
    dialog.value = false;
    name.value = undefined;
    errors.value = undefined;
    if (form.value) form.value.reset();
  }
  watch(dialog, (newVal) => {
    if (newVal) return;
    close();
  });

  function open() {
    name.value = '';
    dialog.value = true;
  }

  const create = useCreateGroup();
  async function submit() {
    assertIsDefined(name.value, 'name');
    updating.value = true;
    const res = await create.execute({ name: name.value });
    if (isFailed(res)) {
      updating.value = false;
      errors.value = res.errors;
      return;
    }
    waitTransition(() => {
      updating.value = false;
      close();
      emit('done');
    });
  }

  const msgs = useMessages({ prefix: 'admin.organisms.groupAddDialog' });
  return {
    dialog,
    form,
    name,
    updating,
    errors,
    labelCreate: msgs.of('create'),
    labelClose: msgs.of('close'),
    close,
    open,
    submit,
  };
}

export type GroupAddDialog = ReturnType<typeof useGroupAddDialog>;
