












import { defineComponent } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import MyApp from '@/base/app/components/MyApp.vue';

import TenantOwnTermsOfService from '../components/organisms/TenantOwnTermsOfService.vue';

export default defineComponent({
  name: 'AdminTenantOwnTermsOfServicePage',
  components: { MyApp, TenantOwnTermsOfService },
  setup() {
    const msgs = useMessages({ prefix: 'admin.pages.tenantOwnTermsOfServicePage' });
    return { title: msgs.of('tenantOwnTermsOfService') };
  },
});
