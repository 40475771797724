import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { GroupLimitation, GroupRole } from '@/base/domains';

import { GroupLimitationFormValue } from './GroupLimitationFormComposable';

const ACTIVE: GroupRole[] = ['trainer', 'mentor', 'trainee'];

export type GroupLimitationSubmitPayload = {
  name: keyof GroupLimitation;
  value: GroupRole[];
  done: () => void;
};

export type PropsGroupLimitation = {
  name: keyof GroupLimitation;
  value: GroupRole[];
};

export function useGroupLimitation(
  props: PropsGroupLimitation,
  emit: (name: string, arg: GroupLimitationSubmitPayload) => void
) {
  const msgs = useMessages({ prefix: 'admin.molecules.groupLimitation' });

  const input = ref<GroupLimitationFormValue>('inactive');
  const inputLabel = computed(() => msgs.of(input.value).value);
  const editing = ref(false);
  const updating = ref(false);

  function init() {
    if (ACTIVE.every((gr) => props.value.includes(gr))) input.value = 'active';
    else input.value = 'inactive';
  }
  onMounted(init);
  watch(() => props.value, init);
  watch(editing, init);

  function done() {
    editing.value = false;
    updating.value = false;
  }

  function submit() {
    updating.value = true;
    const v: GroupRole[] = [];
    if (input.value === 'active') v.push(...ACTIVE);
    emit('submit', { name: props.name, value: v, done });
  }

  return {
    input,
    inputLabel,
    editing,
    updating,
    labelEdit: msgs.of('edit'),
    labelUpdate: msgs.of('update'),
    labelCancel: msgs.of('cancel'),
    submit,
  };
}
