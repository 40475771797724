



























































import { defineComponent } from '@vue/composition-api';

import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';

import GroupDescription from '../molecules/GroupDescription.vue';
import GroupLimitations from '../molecules/GroupLimitations.vue';
import GroupName from '../molecules/GroupName.vue';
import GroupUsers from '../molecules/GroupUsers.vue';
import { PropsGroup, useGroup } from './GroupComposable';

type Props = PropsGroup;

export default defineComponent({
  name: 'AdminGroup',
  components: { GroupName, GroupDescription, GroupLimitations, GroupUsers, BaseDialogOk },
  props: {
    id: { type: String, required: true },
    readonly: { type: Boolean, default: true },
  },
  setup(props: Props) {
    return useGroup(props);
  },
});
