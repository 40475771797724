import { computed, onMounted, ref } from '@vue/composition-api';

import {
  GROUP_DISABLE_FAILED_GROUP_EXAM_IS_OPEN,
  GROUP_REMOVE_FAILED_GROUP_EXAM_IS_OPEN,
} from '@/admin/ErrorCodes';
import { useDisableGroup } from '@/admin/usecases/DisableGroup';
import { useEnableGroup } from '@/admin/usecases/EnableGroup';
import { useMessages } from '@/base/app';
import { toQuery } from '@/base/app/components/atoms/ReturnButtonComposable';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import { BaseDialogOk } from '@/base/app/components/molecules/BaseDialogOkComposable';
import {
  ErrorMessage,
  ReplaceError,
} from '@/base/app/components/molecules/ErrorMessagesComposable';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { Group, User } from '@/base/domains';
import { ApplicationError } from '@/base/error';
import { isFailed } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { readonly, useRoute, useRouter } from '@/utils/VueUtils';

import { useGetGroups, useGetUsers, useRemoveGroup } from '../../../usecases';
import { GroupTable, GroupTableActionPayload } from '../molecules/GroupTableComposable';
import { GroupAddDialog } from './GroupAddDialogComposable';

const MENUS_ENABLED = [
  { value: 'add' },
  { value: 'edit', single: true },
  { value: 'disable', required: true },
  { value: 'remove', required: true },
];

const MENUS_DISABLED = [
  { value: 'enable', required: true },
  { value: 'remove', required: true },
];

function useTable() {
  const table = ref<GroupTable>();
  function clear() {
    assertIsDefined(table.value);
    table.value.clear();
  }
  return { table, clear };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function open(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, open };
}

function useErrorDialog() {
  const errorDialog = ref<BaseDialogOk>();
  function error(errors: ErrorMessage[], replace?: ReplaceError[]) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.error(errors, replace);
  }
  return { errorDialog, error };
}

function useAddDialog() {
  const addDialog = ref<GroupAddDialog>();
  function open() {
    assertIsDefined(addDialog.value);
    addDialog.value.open();
  }
  return { addDialog, open };
}

export function useGroups() {
  const msgs = useMessages({ prefix: 'admin.organisms.groups' });
  const route = useRoute();
  const router = useRouter();

  const { table, clear: clearTableSelection } = useTable();
  const { confirmDialog, open: confirm } = useConfirmDialog();
  const { errorDialog, error } = useErrorDialog();
  const { addDialog, open: add } = useAddDialog();

  const groups = ref<Group[]>([]);
  const usersEnabled = ref<User[]>([]);
  const disabled = ref(false);
  const loading = ref(false);

  const getUsers = useGetUsers();
  async function fetchUsers() {
    loading.value = true;
    const res = await getUsers.execute();
    loading.value = false;
    if (isFailed(res)) {
      usersEnabled.value = [];
      return;
    }
    usersEnabled.value = res.users.filter((u) => u.enabled);
  }

  const getGroups = useGetGroups();
  async function fetchGroups() {
    loading.value = true;
    const res = await getGroups.execute();
    loading.value = false;
    if (isFailed(res)) {
      groups.value = [];
      return;
    }
    groups.value = res.groups;
  }

  function fetch() {
    fetchGroups();
    fetchUsers();
  }
  onMounted(fetch);

  function done() {
    clearTableSelection();
    fetchGroups();
  }

  const removeGroup = useRemoveGroup();
  async function remove(ids: string[]) {
    const ret = await Promise.all(ids.map((id) => removeGroup.execute({ id })));
    const errors = ret.flatMap((r) => (isFailed(r) ? r.errors : []));
    if (errors.length === 0) return true;
    return errors;
  }

  const enableGroup = useEnableGroup();
  async function enable(ids: string[]) {
    const ret = await Promise.all(ids.map((id) => enableGroup.execute({ groupId: id })));
    const errors = ret.flatMap((r) => (isFailed(r) ? r.errors : []));
    if (errors.length === 0) return true;
    return errors;
  }

  const disableGroup = useDisableGroup();
  async function disable(ids: string[]) {
    const ret = await Promise.all(ids.map((id) => disableGroup.execute({ groupId: id })));
    const errors = ret.flatMap((r) => (isFailed(r) ? r.errors : []));
    if (errors.length === 0) return true;
    return errors;
  }

  function toMessageParamsRemove(aes: ApplicationError[]) {
    const exams = aes
      .map((e) => {
        if (!e.payload) return [];
        const p = e.payload as Parameters<
          typeof GROUP_REMOVE_FAILED_GROUP_EXAM_IS_OPEN.toApplicationError
        >[0];
        return p?.exams.map((exam) => `${exam.courseName} > ${exam.contentName}`) ?? [];
      })
      .flat();
    return { exams: exams.join('\n') };
  }

  function toMessageParamsDisable(aes: ApplicationError[]) {
    const exams = aes
      .map((e) => {
        if (!e.payload) return [];
        const p = e.payload as Parameters<
          typeof GROUP_DISABLE_FAILED_GROUP_EXAM_IS_OPEN.toApplicationError
        >[0];
        return p?.exams.map((exam) => `${exam.courseName} > ${exam.contentName}`) ?? [];
      })
      .flat();
    return { exams: exams.join('\n') };
  }

  async function action({ event, selected }: GroupTableActionPayload) {
    switch (event) {
      case 'add':
        add();
        break;
      case 'edit':
      case 'show': {
        const [first] = selected;
        assertIsDefined(first, 'first');
        const query = { edit: event === 'edit' ? '' : undefined, ...toQuery(route) };
        router.push({ name: 'adminGroup', params: { id: first.id }, query });
        break;
      }
      case 'remove': {
        if (selected.length === 0) return;
        confirm(msgs.of('confirmRemove').value, async () => {
          loading.value = true;
          const ret = await remove(selected.map((item) => item.id));
          if (ret !== true) {
            loading.value = false;
            error(ret, [
              {
                from: 'AD0004',
                to: {
                  prefix: 'admin.organisms.groups',
                  key: 'failedRemoveExamIsOpen',
                  values: toMessageParamsRemove,
                },
              },
            ]);
            return;
          }
          waitTransition(done);
        });
        break;
      }
      case 'enable': {
        if (selected.length === 0) return;
        loading.value = true;
        const ret = await enable(selected.map((item) => item.id));
        if (ret !== true) {
          loading.value = false;
          error(ret);
          loading.value = false;
          return;
        }
        waitTransition(done);
        break;
      }
      case 'disable': {
        if (selected.length === 0) return;
        loading.value = true;
        const ret = await disable(selected.map((item) => item.id));
        if (ret !== true) {
          loading.value = false;
          error(ret, [
            {
              from: 'AD0003',
              to: {
                prefix: 'admin.organisms.groups',
                key: 'failedDisableExamIsOpen',
                values: toMessageParamsDisable,
              },
            },
          ]);
          return;
        }
        waitTransition(done);
        break;
      }
      default:
    }
  }

  const menus = computed(() =>
    (disabled.value ? MENUS_DISABLED : MENUS_ENABLED).map((m) => ({
      ...m,
      label: msgs.of(m.value).value,
    }))
  );

  const items = computed(() => groups.value.filter((g) => g.enabled === !disabled.value));

  return {
    table,
    confirmDialog,
    errorDialog,
    addDialog,
    tenantUsers: readonly(usersEnabled),
    items,
    disabled,
    loading,
    menus,
    labelShowDisable: msgs.of('showDisable'),
    clearTableSelection,
    action,
    done,
  };
}
