import { computed, onMounted, ref } from '@vue/composition-api';

import {
  useConfirmEditingTenantOwnTermsOfService,
  useCreateEditingTenantOwnTermsOfService,
  useDeleteEditingTenantOwnTermsOfService,
  useGetEditingTenantOwnTermsOfService,
  useUpdateEditingTenantOwnTermsOfService,
} from '@/admin/usecases';
import { useMessages } from '@/base/app';
import { BaseMarkdownClickAnchorPayload } from '@/base/app/components/atoms/BaseMarkdownComposable';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { DialogAnchorConfirm } from '@/base/app/components/organisms/DialogAnchorConfirmComposable';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { LocalDateTime } from '@/base/types';
import { isFailed } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRouter } from '@/utils/VueUtils';

import {
  TenantOwnTermsOfServiceFormClickAnchorPayload,
  TenantOwnTermsOfServiceFormValue,
} from '../molecules/TenantOwnTermsOfServiceFormComposable';

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function confirm(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, confirm };
}

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(
    payload: TenantOwnTermsOfServiceFormClickAnchorPayload | BaseMarkdownClickAnchorPayload
  ) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

export function useTenantOwnTermsOfServiceDraft() {
  const msgs = useMessages({ prefix: 'admin.organisms.tenantOwnTermsOfServiceDraft' });
  const router = useRouter();
  const { confirmDialog, confirm } = useConfirmDialog();

  const termsOfServiceDraft = ref<{ body: string; updatedAt: LocalDateTime }>();
  const confirmedTermsOfServiceVersion = ref<number>();
  const title = computed(() => {
    if (!termsOfServiceDraft.value) return undefined;
    return msgs.of('updatedAt', {
      date: termsOfServiceDraft.value.updatedAt.format('YYYY/MM/DD HH:mm'),
    }).value;
  });
  const body = computed(() => termsOfServiceDraft.value?.body ?? '');
  const existsConfirmed = ref(false);

  const loading = ref(false);
  const editing = ref(false);
  const updating = ref(false);
  const errors = ref<ErrorMessage[]>();

  const menus = computed(() => {
    const keys = [{ value: 'edit' }];
    if (termsOfServiceDraft.value) keys.push({ value: 'remove' });
    if (body.value) keys.push({ value: 'open' });
    if (existsConfirmed.value) keys.push({ value: 'moveTo' });
    return keys.map((item) => ({ ...item, label: msgs.of(item.value).value }));
  });

  const getTenantOwnTermsOfService = useGetEditingTenantOwnTermsOfService();
  async function fetch() {
    errors.value = undefined;
    loading.value = true;
    const res = await getTenantOwnTermsOfService.execute({});
    loading.value = false;
    if (isFailed(res)) {
      router.replace({ name: 'notFound' });
      return;
    }
    termsOfServiceDraft.value = res.editingTenantOwnTermsOfService;
    confirmedTermsOfServiceVersion.value = res.tenantOwnTermsOfService?.version;
    existsConfirmed.value = !!res.tenantOwnTermsOfService;
  }

  async function init() {
    await fetch();
    editing.value = !body.value;
  }
  onMounted(init);

  const create = useCreateEditingTenantOwnTermsOfService();
  const update = useUpdateEditingTenantOwnTermsOfService();
  async function submit(value: TenantOwnTermsOfServiceFormValue) {
    errors.value = undefined;
    updating.value = true;
    if (!termsOfServiceDraft.value) {
      const res = await create.execute({
        body: value,
      });
      if (isFailed(res)) {
        errors.value = res.errors;
        updating.value = false;
        return;
      }
      termsOfServiceDraft.value = res.editingTenantOwnTermsOfService;
    } else {
      const res = await update.execute({
        body: value,
        version: confirmedTermsOfServiceVersion.value,
      });
      if (isFailed(res)) {
        errors.value = res.errors;
        updating.value = false;
        return;
      }
      termsOfServiceDraft.value = res.editingTenantOwnTermsOfService;
    }
    updating.value = false;
    editing.value = false;
  }

  const remove = useDeleteEditingTenantOwnTermsOfService();
  async function removeDraft() {
    errors.value = undefined;
    updating.value = true;
    const res = await remove.execute({});
    if (isFailed(res)) {
      errors.value = res.errors;
      updating.value = false;
      return;
    }
    waitTransition(() => {
      termsOfServiceDraft.value = undefined;
      updating.value = false;
      router.replace({ name: 'adminTenantOwnTermsOfService' });
    });
  }

  const open = useConfirmEditingTenantOwnTermsOfService();
  async function confirmDraft() {
    errors.value = undefined;
    updating.value = true;
    const res = await open.execute({ version: confirmedTermsOfServiceVersion.value });
    if (isFailed(res)) {
      errors.value = res.errors;
      updating.value = false;
      return;
    }
    waitTransition(() => {
      termsOfServiceDraft.value = undefined;
      updating.value = false;
      router.replace({ name: 'adminTenantOwnTermsOfService' });
    });
  }

  function action(name: string) {
    switch (name) {
      case 'edit':
        editing.value = true;
        break;
      case 'remove':
        confirm(msgs.of('confirmRemove').value, removeDraft);
        break;
      case 'open':
        confirm(msgs.of('confirmOpen').value, confirmDraft);
        break;
      case 'moveTo':
        router.push({ name: 'adminTenantOwnTermsOfService', query: { force: '' } });
        break;
      default:
    }
  }

  return {
    confirmDialog,
    title,
    body,
    loading,
    editing,
    updating,
    errors,
    menus,
    noData: msgs.of('noData'),
    labelMenu: msgs.of('menu'),
    labelCancel: msgs.of('cancel'),
    labelUpdate: msgs.of('update'),
    submit,
    action,
    ...useAnchorDialog(),
  };
}
