import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { assertIsDefined } from '@/utils/Asserts';

export type PropsGroupName = {
  value?: string;
};

export function useGroupNameForm(props: PropsGroupName, emit: (name: string, arg: string) => void) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<string>();

  function init() {
    input.value = props.value ?? '';
  }
  onMounted(init);
  watch(() => props.value, init);

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    assertIsDefined(input.value, 'input');
    emit('submit', input.value);
  }

  function reset() {
    if (!observer.value) return;
    input.value = undefined;
    observer.value.reset();
  }

  const msgs = useMessages({ prefix: 'admin.molecules.groupNameForm' });
  return { observer, input, label: msgs.of('name'), submit, reset };
}

export type GroupNameForm = ReturnType<typeof useGroupNameForm>;
