




















































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import BaseTextarea from '@/base/app/components/atoms/BaseTextarea.vue';

import { PropsGroupDescription, useGroupDescription } from './GroupDescriptionComposable';

type Props = PropsGroupDescription;

export default defineComponent({
  name: 'AdminGroupDescription',
  components: { BaseButton, BaseButtonIcon, BaseMarkdown, BaseTextarea },
  props: {
    value: { type: String, default: undefined },
    readonly: { type: Boolean, default: false },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useGroupDescription(props, emit);
  },
});
