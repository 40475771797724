import { computed, readonly, ref } from '@vue/composition-api';

import { Group, GroupLimitation, GroupRole, User } from '@/base/domains';
import { isFailed } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf } from '@/utils/VueUtils';

import {
  useAddGroupUser,
  useChangeGroupDescription,
  useChangeGroupLimitations,
  useChangeGroupName,
  useGetGroupByAdmin,
  useGetUsers,
  useRemoveGroupUser,
} from '../../usecases';

export function useGroupStore() {
  const group = ref<Group>();
  const tenantUsers = ref<User[]>();
  const loading = ref(false);

  const getUsers = useGetUsers();
  const getGroup = useGetGroupByAdmin();
  async function fetch(groupId: string) {
    loading.value = true;

    const [retUsers, retGroup] = await Promise.all([
      getUsers.execute(),
      getGroup.execute({ groupId }),
    ]);
    loading.value = false;

    if (isFailed(retUsers) || isFailed(retGroup)) {
      group.value = undefined;
      tenantUsers.value = undefined;
      return;
    }

    group.value = retGroup.group;
    tenantUsers.value = retUsers.users;
  }

  const updateName = useChangeGroupName();
  async function changeGroupName(name: string) {
    assertIsDefined(group.value, 'group');
    const { id } = group.value;
    const res = await updateName.execute({ id, name });
    if (isFailed(res)) return res.errors;
    group.value = res.group;
    return true;
  }

  const updateDescription = useChangeGroupDescription();
  async function changeGroupDescription(description: string) {
    assertIsDefined(group.value, 'group');
    const { id: groupId } = group.value;
    const res = await updateDescription.execute({ groupId, description });
    if (isFailed(res)) return res.errors;
    group.value = res.group;
    return true;
  }

  const updateLimitations = useChangeGroupLimitations();
  async function changeLimitations(limitations: GroupLimitation) {
    assertIsDefined(group.value, 'group');
    const { id } = group.value;
    const res = await updateLimitations.execute({ id, limitations });
    if (isFailed(res)) return res.errors;
    group.value = res.group;
    return true;
  }

  const addGroupUsers = useAddGroupUser();
  const removeGroupUsers = useRemoveGroupUser();
  async function changeGroupUsers(userIds: string[], role?: GroupRole) {
    assertIsDefined(group.value, 'group');
    const { id } = group.value;
    if (role) {
      const res = await addGroupUsers.execute({ id, userIds, role });
      if (isFailed(res)) return res.errors;
      group.value = res.group;
    } else {
      const res = await removeGroupUsers.execute({ id, userIds });
      if (isFailed(res)) return res.errors;
      group.value = res.group;
    }
    return true;
  }

  const notFound = computed(() => !group.value);

  return {
    group: readonly(group),
    tenantUsers: readonly(tenantUsers),
    loading,
    notFound,
    fetch,
    changeGroupName,
    changeGroupDescription,
    changeLimitations,
    changeGroupUsers,
  };
}

export type GroupStore = ReturnType<typeof useGroupStore>;

export const GroupStoreKey = injectionKeyOf<GroupStore>({
  boundedContext: 'admin',
  type: 'store',
  name: 'GroupStore',
});
