import { computed, ref } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { GroupRole, Role, UserTagReference } from '@/base/domains';

import { useMessages } from '../../Messages';
import { BaseTable, BaseTableActionPayload } from './BaseTableComposable';

const HEADERS: DataTableHeader[] = [
  { value: 'name', text: '', class: 'base-table-name' },
  { value: 'role', text: '', width: 200 },
  { value: 'groupRole', text: '', width: 200 },
  { value: 'code', text: '', class: 'base-table-name' },
  { value: 'email', text: '', class: 'base-table-name' },
  { value: 'userStatusLabel', text: '', width: 200 },
  { value: 'preview', text: '', sortable: false, class: 'base-user-table-preview' },
];

function useTable() {
  const table = ref<BaseTable>();
  function clear() {
    if (table.value) table.value.clearSelection();
  }
  return { table, clear };
}

export type UserTableItem = {
  id: string;
  name?: string;
  code?: string;
  email?: string;
  role: Role;
  groupRole?: GroupRole;
  userStatus?: 'enabled' | 'disabled' | 'not_signed_up';
  tags?: UserTagReference[];
};

export type UserTableActionPayload = {
  event: string;
  selected: UserTableItem[];
};

export type PropsUserTable = {
  users: UserTableItem[];
  headerKeys: string[];
};

export function useUserTable(
  props: PropsUserTable,
  emit: (name: string, args: UserTableActionPayload) => void
) {
  const msgs = useMessages({ prefix: 'base.molecules.userTable' });

  const headers = computed(() =>
    props.headerKeys
      .map((value) => HEADERS.find((h) => h.value === value))
      .filter((h) => !!h)
      .map((h) => ({ ...h, text: h ? msgs.of(h.value).value : '' }))
  );

  const items = computed(() => {
    const notSignedUpUserName = msgs.of('notSignedUpUserName').value;
    const hasCode = props.headerKeys.includes('code');
    return props.users
      .map((u) => {
        return {
          ...u,
          name: u.userStatus === 'not_signed_up' && !u.name ? notSignedUpUserName : u.name,
          role: msgs.of(u.role).value,
          groupRole: u.groupRole ? msgs.of(u.groupRole).value : '',
          userStatusLabel: u.userStatus ? msgs.of(u.userStatus).value : '',
          preview: u.tags?.map((t) => t.text).join(),
        };
      })
      .sort((a, b) => {
        if (hasCode && a.code && b.code) return a.code < b.code ? -1 : 1;
        if (a.name && b.name) return a.name < b.name ? -1 : 1;
        return a.id < b.id ? -1 : 1;
      });
  });

  function action(payload: BaseTableActionPayload) {
    const selected = payload.selected
      .map((id) => props.users.find((u) => u.id === id))
      .filter((u) => u) as UserTableItem[];
    emit('action', { ...payload, selected });
  }

  return { headers, items, action, ...useTable() };
}

export type UserTable = ReturnType<typeof useUserTable>;
