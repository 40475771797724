

































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import UserTag from '@/base/app/components/atoms/UserTag.vue';
import UserTable from '@/base/app/components/molecules/UserTable.vue';
import { GroupUser, SignUpType, User } from '@/base/domains';

import { PropsGroupUsers, useGroupUsers } from './GroupUsersComposable';

type Props = PropsGroupUsers;

export default defineComponent({
  name: 'AdminGroupUsers',
  components: { UserTable, UserTag },
  props: {
    users: { type: Array as PropType<GroupUser[]>, default: () => [] },
    tenantUsers: { type: Array as PropType<User[]>, default: () => [] },
    tenantSignUpType: { type: String as PropType<SignUpType>, default: 'email' },
    readonly: { type: Boolean, default: false },
    storeOptionId: { type: String, required: true },
  },
  emits: ['add', 'remove'],
  setup(props: Props, { emit }: SetupContext) {
    return useGroupUsers(props, emit);
  },
});
