





















import { computed, defineComponent, provide } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import MyApp from '@/base/app/components/MyApp.vue';

import Group from '../components/organisms/Group.vue';
import { GroupStoreKey, useGroupStore } from '../stores';

type Props = {
  edit: boolean;
};

export default defineComponent({
  name: 'AdminGroupPage',
  components: { MyApp, Group },
  props: {
    id: { type: String, required: true },
    edit: { type: Boolean, default: false },
  },
  setup(props: Props) {
    const store = useGroupStore();
    provide(GroupStoreKey, store);

    const msgs = useMessages({ prefix: 'admin.pages.groupPage' });
    const label = computed(() => {
      if (!store.group.value) return undefined;
      return store.group.value.name;
    });
    const readonly = computed(() => !(store.group.value?.enabled && props.edit));
    const icon = computed(() => (readonly.value ? 'mdi-pencil-off' : 'mdi-pencil'));
    const iconDescription = computed(
      () => msgs.of(readonly.value ? 'cannotEdit' : 'canEdit').value
    );

    return {
      label,
      readonly,
      icon,
      iconDescription,
      loading: store.loading,
      notFound: store.notFound,
    };
  },
});
