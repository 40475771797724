


































import { defineComponent } from '@vue/composition-api';

import RefreshButton from '@/base/app/components/atoms/RefreshButton.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';

import GroupTable from '../molecules/GroupTable.vue';
import GroupAddDialog from './GroupAddDialog.vue';
import { useGroups } from './GroupsComposable';

export default defineComponent({
  name: 'AdminGroups',
  components: {
    BaseDialogConfirm,
    BaseDialogOk,
    RefreshButton,
    GroupTable,
    GroupAddDialog,
  },
  setup() {
    return useGroups();
  },
});
